const inter = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6974571",
    space_js: "//tapbfdh.htinfotech.cn/production/rmlu_ni/j_n/resource/v.js"
  }
];

const banner = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6974573",
    space_js: "//tapbfdh.htinfotech.cn/site/t/on_w_pk/resource/lpg.js"
  }
];

const Native1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6974572",
    space_js: "//tapbfdh.htinfotech.cn/site/s/resource/n-m-vojk/openjs/oc.js"
  }
];

const Native2 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6974574",
    space_js: "//tapbfdh.htinfotech.cn/source/upox/common/ql_m/source/ql.js"
  }
];

const Native3 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6974575",
    space_js: "//tapbfdh.htinfotech.cn/production/vqp/common/y_rm/production/n/resource/rn.js"
  }
];

export default {
  HomeInter: inter,
  // HomeDialog: Native1,
  // HomeNativeInter: Native1,
  HomeBanner: banner,
  HomeNative: Native1,
  HomeNativeCenter: Native2,
  HomeNativeBottom: Native3,
  HotInter: inter,
  HotBanner: banner,
  HotNative: Native1,
  DetailInter: inter,
  DetailBanner: banner,
  DetailNative: Native1,
  DetailNativeCenter: Native2,
  DetailNativeBottom: Native3,
  // ArticleListNative: Native2,
  // ArticleListBanner: Native3,
  // ArticleDetailNative: Native2,
  // ArticleDetailNativeCenter: Native4,
  // ArticleDetailNativeBottom: Native5,
  // ArticleDetailBanner: Native3,
  // ArticleDetailInter: inter,
  // CategoryBanner: Native3,
  // TopicTriviaNative: Native2,
  // TopicTriviaBanner: Native3,
  // TopicTriviaDialog: Native1,
  // TopicKepuNative: Native2,
  // TopicKepuBanner: Native3,
  // TopicKepuDialog: Native1,
  // TopicKepuInter: inter
};
